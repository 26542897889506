.splash-screen {
  transition: opacity 0.5s, visibility 0.5s;
}

.splash-screen--visible {
  opacity: 1;
  visibility: visible;
}

.splash-screen--hidden {
  opacity: 0;
  visibility: hidden;
  display:none;
}