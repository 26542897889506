@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
/*@import "@fontsource/poppins";*/
@import "@fontsource/poppins/400.css"; /* Regular */
@import "@fontsource/poppins/600.css"; /* SemiBold */
@import "@fontsource/poppins/700.css"; /* Bold */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-display: swap;

}

/* Diseño */
/* Añade esto al final de tu archivo CSS global */
/* Añade esto al final de tu archivo CSS global si no lo has hecho ya */
body.drawer-open {
  margin-right: 400px;
  transition: margin-right 0.3s ease-in-out;
}

@media (max-width: 600px) {
  body.drawer-open {
    margin-right: 0;
    overflow: hidden;
  }
}

/* Asegúrate de que el contenido principal tenga una transición suave */
#root, .App, main {
  transition: margin-right 0.3s ease-in-out;
}