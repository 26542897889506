/* Contenedor del flip card */
.flip-container {
  perspective: 1000px;
  position: relative;
  width: 100%;
  height: 100%;
}

/* Tarjetas con la animación de flip */
.flip-card {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1.2s ease;
  transform-style: preserve-3d;
}

.flip-card.back {
  transform: rotateY(180deg);
}

/* Lados del flip */
.flip-card-front,
.flip-card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.flip-card-front {
  transform: rotateY(0deg); /* Frente visible */
}

.flip-card-back {
  transform: rotateY(180deg);
}

/* Animación cuando se carga el contenedor flip */
@keyframes flip-in {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

.flip-container {
  animation: flip-in 0.6s ease-out;
}

/* Estilos específicos para móviles */
@media (max-width: 768px) {
  .flip-container {
    perspective: 800px !important;
    height: auto;
    margin-bottom: 592px;
  }

  .flip-card-front,
  .flip-card-back {
    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
  }

  .flip-card {
    transform: none;
    position: static;
  }

  .flip-card.back {
    transform: none; 
  }
}