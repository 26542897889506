.products-page-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .product-page-gallery-main .item img {
    width: 300px;
    height: 400px;
    object-fit: cover;
   
  }
  
  .product-page-gallery-preview {
    display: flex;
    gap: 10px;
  }
  
  .product-page-gallery-preview .item {
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .product-page-gallery-preview .item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid #ccc;
  }
  
 
  .product-page-gallery-preview .item:hover {
    transform: scale(1.1);
  }
  