@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 5px; 
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 3px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}




.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-selector {
  background: linear-gradient(43deg, #cc63d3c7, #a55aeec7, #62cefb);
  background-size: 400% 200%;
    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
}

.css-gestion-tienda {
  background: rgba(255, 255, 255, 0.26) ;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
}

.css-backgroundbtn{
  background: rgba(0, 0, 0, 0.26) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}




@-webkit-keyframes AnimationName {
    0%{background-position:0% 92%}
    50%{background-position:100% 9%}
    100%{background-position:0% 92%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 92%}
    50%{background-position:100% 9%}
    100%{background-position:0% 92%}
}
@keyframes AnimationName {
    0%{background-position:0% 92%}
    50%{background-position:100% 9%}
    100%{background-position:0% 92%}
}

/* src/index.css o cualquier archivo CSS global */
h1 {
  @apply text-3xl font-bold mb-4;
}

h2 {
  @apply text-2xl font-semibold mb-3;
}

p {
  @apply mb-2;
}

ol {
  @apply list-decimal ml-6 mb-2;
}

li {
  @apply mb-1;
}

ul {
  @apply list-disc ml-6 mb-2;
}