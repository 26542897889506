/* En aurora.css */
.aurora-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  mix-blend-mode: screen; /* Clave para la fusión de colores */
}

.aurora-container canvas {
  width: 100% !important;
}